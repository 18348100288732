import React from 'react';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro'
import {iconPrefix} from '@arboxappv4/shared/src/helpers/constants'

const iconGenerator = ({name, type}) => {
    const iconType = type ?? iconPrefix.LIGHT;
    if(iconType === iconPrefix.SOLID) {
        switch (name) {
            case 'rocket-launch':
                return solid('rocket-launch');
            case 'user':
                return solid('user');
            case 'location-pin':
                return solid('location-pin');
            case 'location-dot':
                return solid('location-dot');
            default:
                return null;
        }
    } else if(iconType === iconPrefix.LIGHT) {
        switch (name) {
            case 'rocket-launch':
                return light('rocket-launch');
            case 'sun-bright':
                return light('sun-bright');
            case 'moon-stars':
                return light('moon-stars');
            case 'envelope':
                return light('envelope');
            case 'mobile-screen-button':
                return light('mobile-screen-button');
            case 'link':
                return light('link');
            case 'share':
                return light('share');
            default:
                return null;
        }
    } else return null;
};

export default iconGenerator;