import React, {useEffect, useRef, useState} from 'react';
import {ClickableDiv, ErrorText, FlexColumnSection, SmallHeader, SmallText} from "../../../styles/globalStyles";
import {signatureTypes, signatureTypesEnum} from "@arboxappv4/shared/src/helpers/constants";
import {Checkbox} from "antd";
import SignatureCanvas from 'react-signature-canvas'
import {t} from "i18next";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const SignatureHandler = (props) => {
    const {digitalForm, signatureType, onSave, error, showCheckboxError, agreeCheckbox, setAgreeCheckbox} = props;
    const [signatureError, setSignatureError] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const signatureRef = useRef(null);
    const checkboxRef = useRef(null);

    useEffect(() => {
        if(checkboxError) {
            // checkboxRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [checkboxError]);

    const sign = () => {
        setSignatureError(false)
        if(agreeCheckbox || signatureType !== signatureTypes[signatureTypesEnum.CHECKBOX_AND_SIGNATURE]) {
            setCheckboxError(false)
            onSave({signature: signatureRef.current.toDataURL(), agree: true})
        } else {
            setCheckboxError(true)
        }
    }

    const onCheckBoxClicked = (agree) => {
        setAgreeCheckbox(agree);
        if(!agree) {
            setCheckboxError(true)
        } else {
            setCheckboxError(false)
            if(signatureType === signatureTypes[signatureTypesEnum.CHECKBOX_AND_SIGNATURE]) {
                if(signatureRef.current.isEmpty()) {
                    setSignatureError(true)
                } else {
                    onSave({signature: signatureRef.current.toDataURL(), agree: true})
                }
            } else {
                onSave({signature: null, agree: true})
            }
        }
    }

    const clearSignature = () => {
        if(signatureRef?.current.isEmpty() === false) {
            signatureRef?.current.clear();
            onSave({signature: null, agree: agreeCheckbox})
            setSignatureError(true)
        }
    }


    return (
        <FlexColumnSection flex={'none'}>
            <SmallHeader fontSize={'18px'}>{t('signature')}</SmallHeader>
            {digitalForm?.signature_declaration && <div className={'waiver-content'} dangerouslySetInnerHTML={{__html: digitalForm.signature_declaration}}/>}
            {(signatureType === signatureTypes[signatureTypesEnum.SIGNATURE_ONLY] || signatureType === signatureTypes[signatureTypesEnum.CHECKBOX_AND_SIGNATURE]) &&
                <FlexColumnSection flex={'none'}>
                    <span>{t('signature-header-explain')}</span>
                    <SignatureCanvas ref={signatureRef} onEnd={sign} canvasProps={{className: signatureError ? 'signature-pad-error' : 'signature-pad'}} backgroundColor={Colors.white}/>
                    <TryAgainBtn onClick={() => clearSignature()}>
                        <FontAwesomeIcon icon={light('arrows-rotate')} color={Colors.siteBlack} size={'sm'}/>
                        <span>{t('signature-try-again')}</span>
                    </TryAgainBtn>
                </FlexColumnSection>}
            {(signatureType === signatureTypes[signatureTypesEnum.CHECKBOX_ONLY] || signatureType === signatureTypes[signatureTypesEnum.CHECKBOX_AND_SIGNATURE]) &&
                <Checkbox value={agreeCheckbox} onChange={(e) => onCheckBoxClicked(e.target.checked)} className={checkboxError ? 'checkbox-error': ''} style={{marginTop: '10px'}}>
                    <SmallText>{t('form-agree-checkbox')}</SmallText>
                </Checkbox>}
            {(checkboxError || showCheckboxError) && <ErrorText ref={checkboxRef}>{t('form-agree-checkbox-error')}</ErrorText>}
        </FlexColumnSection>
    );
}

export default SignatureHandler;

const TryAgainBtn = styled(ClickableDiv)`
    gap: 5px;
    display: flex;
    align-items: center;
`;


