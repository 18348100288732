import React, {useEffect, useState} from 'react';
import {
    addFiltersToQueryParams,
    getBookingCtaText,
    isLocationIL
} from "../../../helpers/functions";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek"
import customParseFormat from "dayjs/plugin/customParseFormat"
import 'dayjs/locale/he'
import {useDispatch, useSelector} from "react-redux";
import {Spin, Tooltip} from "antd";
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import orderBy from "lodash-es/orderBy";
import groupBy from 'lodash-es/groupBy';
import {
    EllipsisText,
    FlexColumnSection,
    FlexRowSection, FlexSection, GreyBox,
    SmallHeader
} from "../../../styles/globalStyles";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import CustomCollapse from "../../../components/UI/CustomCollapse";
import {t} from "i18next";
import {bookingTypes} from "@arboxappv4/shared/src/helpers/constants";
import UserAvatar from "../../../components/UserAvatar";
import {getDisabledPagesProps} from "@arboxappv4/shared/src/helpers/DisablePages";
import styled from "styled-components";
import GroupSessionsHeader from "./GroupSessionsHeader";
import {CONFIRMATION_MODAL, openModal, SESSION_MEMBERSHIP_MODAL} from "@arboxappv4/shared/src/redux/modalManagerSlice";
import SingleItemPage from "../SingleItemPage";
import SessionBookingInfo from "../../../components/SessionBookingInfo";
import CalendarView from "./CalendarView";
import SessionFullInfo from "../../../components/SessionFullInfo";
dayjs.extend(isoWeek)
dayjs.extend(customParseFormat)

const GroupSessions = (props) => {
    const { handleSubmit, values, setFieldValue, isDirectSession } = props;
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const language = useSelector(state => state.site.language)
    const box = useSelector(state => state.site.box)
    const flowRes = useSelector(state => state.stepper.flowRes)
    const error = useSelector(state => state.stepper.error)

    const [fetching, setFetching] = useState(false);
    const [filteredSessions, setFilteredSessions] = useState(null);
    const [allSessions, setAllSessions] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [dates, setDates] = useState(null);
    const [toFromDates, setToFromDates] = useState(null);
    const [selectedProps, setSelectedProps] = useState({id: null, payForSlot: null, schedule: null});
    const [submitted, setSubmitted] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [showConfirmAfterConfirm, setShowConfirmAfterConfirm] = useState(null);
    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const showCalendar = true // !!params.calendarView

    useEffect(() => {
        if(globalLocation) {
            if(params.allLocations === 'true') {
                setSelectedLocation('all')
            } else {
                setSelectedLocation(globalLocation?.id)
            }
        }
    }, [globalLocation]);

    useEffect(() => {
        if(flowRes?.showSuccessToast && !isDirectSession) {
            fetchClasses({to: toFromDates.to, from: toFromDates.from, locations_box_id: selectedLocation})
        }
        if(flowRes) {
            setSelectedProps({id: null, payForSlot: null, schedule: null})
            setSubmitted(false)
        }
    }, [flowRes]);

    useEffect(() => {
        //this is to be 100% sure we don't submit before the values are properly submitted (because setFieldValue is async)
        if(values.id === selectedProps.id &&
            values.payForSlot === selectedProps.payForSlot &&
            values.schedule &&
            values.schedule?.id === selectedProps.schedule?.id &&
            !submitted
        ) {
            handleSubmit()
            setSubmitted(true)
        }
    }, [values.id, values.payForSlot, selectedProps, submitted]);

    useEffect(() => {
        if(filteredSessions) {
            const empty = Object.values(filteredSessions).every((sessions) => sessions.length === 0)
            setIsEmpty(empty)
        }
    }, [filteredSessions]);

    useEffect(() => {
        if(!isDirectSession && selectedLocation) {
            const toFrom = getToFromDates()
            fetchClasses({
                to: toFrom.to,
                from: toFrom.from,
                locations_box_id: selectedLocation === 'all' ? null : selectedLocation
            })
            if (selectedLocation !== 'all') addFiltersToQueryParams({location: selectedLocation, allLocations: false})
            else addFiltersToQueryParams({allLocations: true})
        }
    }, [selectedLocation, isDirectSession]);

    useEffect(() => {
        if(error) {
            setSelectedProps({id: null, payForSlot: null, schedule: null})
            setSubmitted(false)
        }
    }, [error]);

    useEffect(() => {
        if(showConfirmAfterConfirm) {
            openCancelBookingConfirm(showConfirmAfterConfirm)
            setShowConfirmAfterConfirm(null)
        }
    }, [showConfirmAfterConfirm]);

    const getToFromDates = () => {
        let dates = {...toFromDates}
        const isIL = isLocationIL()
        if(!dates?.from) {
            dates.from = isMobile ? dayjs().format(DB_DATE_FORMAT) : dayjs().startOf(isIL ? 'week' : 'isoWeek').format(DB_DATE_FORMAT);
        }
        if(!dates?.to) {
            dates.to = isMobile ? dates.from : dayjs().endOf(isIL ? 'week' : 'isoWeek').format(DB_DATE_FORMAT);
        }
        return dates
    }

    const fetchClasses = async (params) => {
        setToFromDates({from: params.from, to: params.to})
        setFetching(true)
        setIsEmpty(false)
        setFilteredSessions(null)
        const dates = getDateRangeArray(params.from, params.to)
        const res = await apiAction(`${!loggedIn ? 'site/' : ''}schedule/betweenDates`, 'post', params);
        groupClassesByDate(res.data, dates)
        setFetching(false)
    }

    const onActionClicked = (session) => {
        if(session.booking_option === bookingTypes.INSERT_SCHEDULE_USER || session.booking_option === bookingTypes.INSERT_STAND_BY) {
            openMembershipsPopup(session)
        } else if(session.booking_option === bookingTypes.PAST) {
            openPastSession(session)
        } else {
            showCalendar ? openCancelBookingInfo(session) : openCancelBookingConfirm(session)
        }
    }

    const openMembershipsPopup = (session) => {
        dispatch(openModal({modalName: SESSION_MEMBERSHIP_MODAL, props: {
            session: session,
            title: session.box_categories.name,
            onMembershipSelect: ({payForSlot, id}) => {
                setSelectedProps({id, payForSlot, schedule: session})
                setFieldValue('payForSlot', payForSlot)
                setFieldValue('id', id)
                setFieldValue('schedule', session)
                setFieldValue('action', session.booking_option)
                if(!loggedIn) setFieldValue('locations_box_fk', session.locations_box_fk)
            }
        }}))
    }

    const openCancelBookingConfirm = async (session) => {
        let lateCancellation = false
        if(session.booking_option === bookingTypes.CANCEL_SCHEDULE_USER) {
            try {
                await apiAction(`scheduleUser/checkLateCancel`, 'post', {schedule_id: session.id});
            } catch (error) {
                lateCancellation = error.error.code === 513
            }
        }
        dispatch(openModal({modalName: CONFIRMATION_MODAL, props: {
            header: t(`${session.booking_option}-confirm-header`),
            explanation: t(`${session.booking_option}${lateCancellation ? '-late' : ''}-confirm-explain`),
            submitText: t('booking-cancel-btn'),
            onConfirm: () => {
                setSelectedProps({id: null, payForSlot: null, schedule: session})
                setFieldValue('id', null)
                setFieldValue('payForSlot', null)
                setFieldValue('schedule', session)
                setFieldValue('action', session.booking_option)
            }
        }}))
    }

    const openCancelBookingInfo = (session) => {
        dispatch(openModal({modalName: CONFIRMATION_MODAL, props: {
            header: session.box_categories.name,
            explanation: (<SessionFullInfo session={session} ignoreDisabledPages={false}/>),
            hideClose: true,
            submitText: t(`${session.booking_option}-cancel`),
            onConfirm: () => setShowConfirmAfterConfirm(session)
        }}))
    }

    const openPastSession = (session) => {
        dispatch(openModal({modalName: CONFIRMATION_MODAL, props: {
            header: session.box_categories.name,
            explanation: <SessionFullInfo session={session} ignoreDisabledPages={false}/>,
            hideClose: true,
            hideSubmit: true,
            footer: <GreyBox><SmallHeader>{t('event-passed-header')}</SmallHeader><span>{t('event-passed-subheader')}</span></GreyBox>,
        }}))
    }

    const groupClassesByDate = (classes, dates) => {
        setIsEmpty(classes.length === 0)
        const sorted = orderBy(classes, ['date','time', session => session.box_categories.name.toLowerCase()]);
        const groupedByDate = groupBy(sorted, 'date');
        const sessionsByDates = dates.reduce((acc, date) => ({...acc, [date]: groupedByDate[date] ?? []}), {})
        setFilteredSessions(sessionsByDates)
        setAllSessions(sessionsByDates)
    }

    const getDateRangeArray = (from, to) => {
        let dateRangeArray = [];
        setDates({from, to})
        let now = dayjs(from, DB_DATE_FORMAT);
        let toDate = dayjs(to, DB_DATE_FORMAT);
        while (now.isBefore(toDate) || now.isSame(toDate)) {
            dateRangeArray.push(now.format(DB_DATE_FORMAT));
            now = now.add(1, 'day');
        }
        return dateRangeArray;
    }

    const getHeaderInfoComp = (session) => {
        const hasMultiLocations = box?.locations_box.length > 1;
        const coachNameType = hasMultiLocations && session.coach && session.second_coach && !isMobile ? 'firstName' : 'full'
        const displayProps = getDisabledPagesProps(session)
        const coachIsDisplayed = displayProps.showCoach && (session.coach || session.second_coach)

        return (
            <div style={{width: isMobile ? '100%' : '80%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? '6px' : 0}}>
                <FlexSection gap={isMobile ? '6px' : '10px'} mobile={isMobile}>
                    <EllipsisText isMobile={isMobile} width={coachIsDisplayed ? '25%' : 'auto'}>{dayjs(session.time, 'HH:mm').format(globalLocation.time_format)} - {dayjs(session.end_time, 'HH:mm').format(globalLocation.time_format)}</EllipsisText>
                    {displayProps.showCoach && session.coach && <UserAvatar user={session.coach} nameType={coachNameType} disabled={session.booking_option === bookingTypes.PAST}/>}
                    {displayProps.showCoach && session.second_coach && <UserAvatar user={session.second_coach} nameType={coachNameType} disabled={session.booking_option === bookingTypes.PAST}/>}
                </FlexSection>
                <FlexSection gap={isMobile ? '6px' : '10px'} flex={'unset'} mobile={isMobile} width={selectedLocation === 'all' && loggedIn && (displayProps.showNumRegistered || displayProps.showNumOpenSpots) ? '35%' : 'auto'}>
                    <FlexSection gap={'5px'} dir={'row'} width={isMobile ? '100%' : '150px'}>
                        <SessionBookingInfo displayProps={displayProps}/>
                        {loggedIn && displayProps.showWaitList && displayProps.inWaitList > 0 && <Tooltip title={t('waitlist-tooltip')}><span>({displayProps.inWaitList})</span></Tooltip>}
                    </FlexSection>
                    {selectedLocation === 'all' && <EllipsisText isMobile={isMobile} width={loggedIn ? '50%' : '100px'}>{session.locations_box.location}</EllipsisText>}
                </FlexSection>
            </div>
        )
    }

    const onFilterChange = (filteredSessions, filtersConfig) => {
        if(filtersConfig?.locations) {
            setSelectedLocation(filtersConfig?.locations.selected ?? null)
        }
        setFilteredSessions(filteredSessions)
    }

    return (
        <>
            {isDirectSession ?
                <SingleItemPage values={values} setFieldValue={setFieldValue} handleSubmit={handleSubmit} params={{...values, onCtaClick: () => onActionClicked(values.schedule)}}/>
                :
                <FlexColumnSection gap={'15px'} padding={isMobile ? '0 16px' : 0}>
                    <GroupSessionsHeader to={dates?.to}
                                         from={dates?.from}
                                         onDateChange={fetchClasses}
                                         sessions={allSessions}
                                         onFiltered={onFilterChange}
                                         selectedLocation={selectedLocation}
                    />
                    <Spin spinning={fetching}/>
                    <>{showCalendar ?
                        <CalendarView filteredSessions={filteredSessions} isEmpty={isEmpty} selectedLocation={selectedLocation} onSessionClicked={onActionClicked}/> :
                        <FlexColumnSection gap={'20px'} overflow={'hidden auto'}>
                            {isEmpty && <span>{t('group-empty-state')}</span>}
                            {filteredSessions && Object.values(filteredSessions).map(sessionsByDate => sessionsByDate.length > 0 ? (
                                <FlexColumnSection gap={'5px'} overflow={'unset'} flex={'unset'}
                                                   key={`sessionsByDate-${sessionsByDate[0].date}`}>
                                    {!isMobile &&
                                    <SmallHeader>{dayjs(sessionsByDate[0].date, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(`dddd, ${language?.code === 'he' ? 'D MMMM' : 'MMMM D'}`)}</SmallHeader>}
                                    <FlexColumnSection gap={'10px'} overflow={'unset'} flex={'unset'}>
                                        {sessionsByDate.map(session => (
                                            <CustomCollapse headerText={session.box_categories.name}
                                                            disableExpand={session.booking_option === bookingTypes.PAST ? 'disabled' : 'icon'}
                                                            ctaText={getBookingCtaText(session.booking_option)}
                                                            ctaWidth={'160px'}
                                                            headerWidth={'20%'}
                                                            headerInfo={getHeaderInfoComp(session)}
                                                            onBtnClick={() => onActionClicked(session)}
                                                            key={`group-panel-${session.id}`}
                                            />
                                        ))}
                                    </FlexColumnSection>
                                </FlexColumnSection>
                            ) : null)}
                        </FlexColumnSection>}
                    </>
                </FlexColumnSection>
            }
        </>
    );
};

export default GroupSessions;
