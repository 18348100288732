import React, {useEffect, useState} from 'react';
import {FlexColumnSection} from "../../styles/globalStyles";
import {Spin} from "antd";
import {useSelector} from "react-redux";
import useScreenSize from "../../helpers/hooks/useScreenSize";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import dayjs from "dayjs";

const Payment = (props) => {
    const { values, setFieldValue, handleSubmit, idPropOverride, startOverride } = props;
    const box = useSelector(state => state.site.box)
    const language = useSelector(state => state.site.language)
    const location = useSelector(state => state.site.selectedLocation)
    const token = useSelector(state => state.auth.token)
    const userBox = useSelector(state => state.user.activeUsersBoxes)
    const error = useSelector(state => state.stepper.error)
    const [loading, setLoading] = useState(true);
    const [iframeSrc, setIframeSrc] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const {isMobile} = useScreenSize()

    useEffect(() => {
        listenToMessages()
        return () => {
            window.removeEventListener("message", listenToMessages);
            setSubmitted(false)
        };
    }, []);

    useEffect(() => {
        if(values.payment_info && !submitted) {
            handleSubmit()
            setSubmitted(true)
        }
    }, [values.payment_info, submitted]);

    useEffect(() => {
        if(box && language && values && Object.keys(values).length > 0) {
            setIframeSrc(getIframeSrc())
        }
    }, [box, language, values, userBox]);


    useEffect(() => {
        if(error) {
            setFieldValue('payment_info', null)
            setIframeSrc(null)
            setSubmitted(false)
            setTimeout(() => setIframeSrc(getIframeSrc()), 10)
        }
    }, [error]);

    const listenToMessages = () => {
        window.addEventListener('message', (event) => {
            if(event.data.type === "PAYMENT_RESPONSE_SUCCESS") {
                console.log('listening.. ', event.data.data)
                setFieldValue('payment_info', event.data.data)
            }
        });
    }

    // name and phone are only for meshulam
    const getUserName = () => {
        const firstName = userBox?.first_name ?? values?.first_name
        const lastName = userBox?.last_name ?? values?.last_name
        if(firstName && lastName) {
            if(firstName.length >= 2 && lastName.length >= 2) {
                return `&name=${firstName} ${lastName}`
            }
        }

        return ''
    }

    const getUserPhone = () => {
        const phone = userBox?.phone ?? values?.phone
        if(phone && (phone[0] === '0' || phone.startsWith('972')) && phone.length >= 10) {
            return `&phone=${phone}`
        }
        return ''
    }

    const getQuantity = () => {
        if(values.cart && values.cart.quantity > 1) {
            return `&quantity=${JSON.stringify({[values.cart.id]: values.cart.quantity})}`
        }
        return ''
    }

    const getStartDates = () => {
        const today = dayjs().format(DB_DATE_FORMAT)
        if(values.cart?.start && (values.cart.start > today)) {
            return `&startDates=${JSON.stringify({[values.cart.id]: values.cart.start})}`
        } else if (startOverride) {
            return `&startDates=${JSON.stringify({[idPropOverride ? values[idPropOverride] : values.cart?.id]: startOverride})}`
        }
        return ''
    }

    const getIframeSrc = () => {
        return `${process.env.PAYMENTS_URL}?box_id=${box.id}&lang=${language?.code}&locations_box_id=${values.locations_box_fk ?? location.id}&cart=${JSON.stringify([idPropOverride ? values[idPropOverride] : values.cart?.id])}&token=${token}${getUserName()}${getUserPhone()}${getQuantity()}${getStartDates()}`
    }

    return (
       <FlexColumnSection padding={isMobile ? '0 16px' : 0} align={'start'}>
           <Spin spinning={loading} style={{alignSelf: 'center', justifyContent:'center', flex: 1}}/>
           {iframeSrc &&
           <iframe frameBorder={0}
                   style={{width: '100%', flex: 1}}
                   onLoad={() => setLoading(false)}
                   src={iframeSrc}/>}
       </FlexColumnSection>
    );
};

export default Payment;