import {CustomFieldContainerSessionInfo, CustomFieldItemSessionInfo} from "../../../styles/globalStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import React, {useMemo} from "react";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {getDisabledPagesProps} from "@arboxappv4/shared/src/helpers/DisablePages";

const CoursesCustomFields = (props) => {
    const { data } = props;
    const {isMobile} = useScreenSize();
    const customFieldsData = data.custom_field_value?.length > 0 ? data.custom_field_value : data.series.custom_field_value;
    const numberDotsPerRow = isMobile ? 2 : 3;

    return (
        <CustomFieldContainerSessionInfo mobile={isMobile}>
            {customFieldsData?.map((field,index) => {
                return  <CustomFieldItemSessionInfo>
                            {field.custom_field.name}: {field.custom_field.props.options.find(option => option.value === field.value)?.label}
                            {(!!((index + 1) % numberDotsPerRow !== 0 && index % numberDotsPerRow !== 2) && (index !== customFieldsData.length -1)) &&
                            <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'} style={{ padding: '10px' }}/>}
                        </CustomFieldItemSessionInfo>

            })}
        </CustomFieldContainerSessionInfo>
    );
};

export default CoursesCustomFields;
