import React, {useMemo} from 'react';
import {FlexColumnSection, FlexRowSection} from "../../../styles/globalStyles";
import {getPayForSlotMembershipType} from "@arboxappv4/shared/src/helpers/functions";
import {light, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {t} from "i18next";
import {useSelector} from "react-redux";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import CoursesCustomFields from "../courses/CoursesCustomFields";

const GroupSessionDescription = (props) => {
    const { session } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)

    const payForSlot = useMemo(() => session ? getPayForSlotMembershipType(session) : null, [session])
    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);

    if(!session) return <></>

    return (
        <FlexColumnSection gap={'20px'}>
            <FlexRowSection gap={'10px'} align={'center'}>
                {payForSlot &&
                    <FlexRowSection gap={'10px'} flex={'none'}>
                        <FontAwesomeIcon icon={light('check')}/>
                        <span>{t('pay-for-slot-available')}</span>
                        <span>|</span>
                        {payForSlot.price > 0 ? <span>{currencySymbol}{payForSlot.price}</span> : <span>{t('free')}</span>}
                    </FlexRowSection>
                }
                {payForSlot && session.live_link && <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'}/>}
                {session.live_link && <span>{t('online-session')}</span>}
                {!payForSlot && !session.live_link && <span>{t('no-group-session-information')}</span>}
            </FlexRowSection>
            <CoursesCustomFields data={session} />
            <span>{session.box_categories.bio}</span>
        </FlexColumnSection>
    );
};

export default GroupSessionDescription;