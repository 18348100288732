import React from 'react';
import useScreenSize from "../helpers/hooks/useScreenSize";
import iconGenerator from "../helpers/IconGenerator";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { message } from 'antd';
import {t} from "i18next";

const ShareButton = (props) => {
    const { link } = props;
    const {isMobile} = useScreenSize()

    const copyOrShare = () => {
        if(isMobile && navigator?.share) {
            navigator.share({
                title: t('share-link-header'),
                url: link,
            })
        } else {
            navigator.clipboard.writeText(link)
            message.success(t('copy-success'));
        }
    }

    return (
        <div onClick={copyOrShare} style={{fontSize: '16px', cursor: 'pointer'}}>
            <FontAwesomeIcon icon={iconGenerator({name: isMobile ? 'share' : 'link'})} color={Colors.siteBlack} size={'md'}/>
        </div>
    );
};

export default ShareButton;