import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {EllipsisText, FlexColumnSection, SmallHeader} from "../../../styles/globalStyles";
import dayjs from "dayjs";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import {getDisabledPagesProps} from "@arboxappv4/shared/src/helpers/DisablePages";
import UserAvatar from "../../../components/UserAvatar";
import SessionBookingInfo from "../../../components/SessionBookingInfo";
import {t} from "i18next";

const GroupSessionInfo = (props) => {
    const { session } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const language = useSelector(state => state.site.language)
    const {isMobile} = useScreenSize()
    const displayProps = useMemo(() => session ? getDisabledPagesProps(session) : {}, [session])

    if(!session) return <></>

    return (
        <FlexColumnSection gap={isMobile ? '10px' : '15px'} flex={'unset'}>
            <span>{dayjs(session.date, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(`dddd, ${language?.code === 'he' ? 'D MMMM' : 'MMMM D'}`)}</span>
            {globalLocation && <span>{dayjs(session.time, 'HH:mm').format(globalLocation.time_format)} - {dayjs(session.end_time, 'HH:mm').format(globalLocation.time_format)}</span>}
            {displayProps.showCoach && session.coach && <UserAvatar user={session.coach} nameType={'full'}/>}
            {displayProps.showCoach && session.second_coach && <UserAvatar user={session.second_coach} nameType={'full'}/>}
            <SessionBookingInfo displayProps={displayProps}/>
            <span>{t('location')}: {session.locations_box.location}</span>
        </FlexColumnSection>
    );
};

export default GroupSessionInfo;